import useWindowSize from "components/UseWindowSize";
import React from "react";
import Select from "react-select";
import "./styles.scss";

export default function SelectInput({
  label = "",
  placeholder = "",
  options = [],
  name = "",
  value = "",
  onChangeText,
  index,
  style,
  readonly,
  ...rest
}) {
  const isMobile = useWindowSize();
  let updatedOptions = options.map((item) => ({ ...item, label: item.key }));
  return (
    <div className={`${isMobile ? 'd-flex justify-content-between align-items-center' : 'd-flex justify-content-between align-items-center'}`}>
      {label && <label className={`label fs14 col-md-4 ${isMobile ? 'p-0' : ''}`}>{label}</label>}
      {/*<select className='select-box' name={name} value={value} onChange={onChangeText} {...rest} style={{ "minWidth": `${style3}px`, ...style }} >
        <option value="">{placeholder}</option>
        {options !== undefined && options.length > 0 && options.map((option, index) => (
          <option value={option.value} key={index}>{option.key}</option>
        ))}
      </select>*/}
      {readonly ? (
        updatedOptions !== undefined && updatedOptions.length > 0 ? (
          <Select
            value={updatedOptions.filter(function (option) {
              return option.value === value;
            })}
            options={updatedOptions}
            defaultValue={
              updatedOptions && value
                ? updatedOptions.filter((option) => option.value === value)[0]
                : updatedOptions[0]
            }
            onChange={(e, index) =>
              onChangeText(
                { target: { name, value: e.value, label: e.label } },
                index
              )
            }
            className={`custom-dropdown col-md-8 ${isMobile ? 'p-0' : ''}`}
            {...rest}
            isDisabled={true}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#2A7A7B',
              },
            })}
          />
        ) : (
          <Select
            value={[{ key: "Loading...", value: "Loading..." }]}
            options={[{ key: "Loading...", value: "Loading" }]}
            defaultValue={{ key: "Loading...", value: "Loading" }}
            className={`custom-dropdown col-md-8 ${isMobile ? 'p-0' : ''}`}
            isDisabled={true}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#2A7A7B',
              },
            })}
          />
        )
      ) : updatedOptions !== undefined && updatedOptions.length > 0 ? (
        <Select
          value={updatedOptions.filter(function (option) {
            // console.log(option)
            return option.value === value;
          })}
          options={updatedOptions}
          defaultValue={
            updatedOptions && value
              ? updatedOptions.filter((option) => option.value === value)[0]
              : updatedOptions[0]
          }
          onChange={(e) =>
            onChangeText(
              { target: { name, value: e.value, label: e.label } },
              index
            )
          }
          className={`custom-dropdown col-md-8 ${isMobile ? 'p-0' : ''}`}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: '#2A7A7B',
            },
          })}
          {...rest}
        />
      ) : (
        <Select
          value={[{ key: "Loading...", value: "Loading..." }]}
          options={[{ key: "Loading...", value: "Loading" }]}
          defaultValue={{ key: "Loading...", value: "Loading" }}
          className={`custom-dropdown col-md-8 ${isMobile ? 'p-0' : ''}`}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: '#2A7A7B',
            },
          })}
        />
      )}
    </div>
  );
}
