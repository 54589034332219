import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Routes from "./routes/index";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { store2 } from "service/helpers";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "react-user-onboarding/dist/index.css";
import "./assets/scss/index.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import withClearCache from "./ClearCache";
import NoInternet from "NoInternet";
import './i18next'; // Initialize i18next

const ClearCacheComponent = withClearCache(MainApp);
const queryClient = new QueryClient();


function App() {
  const [isOnline, setIsOnline] = useState(true);
  useEffect(() => {
    const checkOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };
    checkOnlineStatus();
    window.addEventListener('online', checkOnlineStatus);
    window.addEventListener('offline', checkOnlineStatus);
    return () => {
      window.removeEventListener('online', checkOnlineStatus);
      window.removeEventListener('offline', checkOnlineStatus);
    };
  }, []);

  return (
    <>
      {
        isOnline ? (
          <ClearCacheComponent />
        ) : (
          <NoInternet />
        )
      }
    </>
  )
}

function MainApp() {
  return (<Routes />)
}
ReactDOM.render(
  <Provider store={store2}>
    <QueryClientProvider client={queryClient}>
      {/*<ReactQueryDevtools initialIsOpen={false} />*/}
      <App />
    </QueryClientProvider>
  </Provider>,
  document.getElementById("root"),
);

serviceWorker.register();
