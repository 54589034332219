export const WRONG_PASSWORD =
  "Password must contain 1 Alphabet, 1 Special Character, 1 Number. Min 8 and Max 16 character length only allowed.";
export const WRONG_EMAIL = "must be a valid Email.";

export const countries = [
  {
    code: "+7 840",
    name: "Abkhazia",
  },
  {
    code: "+93",
    name: "Afghanistan",
  },
  {
    code: "+355",
    name: "Albania",
  },
  {
    code: "+213",
    name: "Algeria",
  },
  {
    code: "+1 684",
    name: "American Samoa",
  },
  {
    code: "+376",
    name: "Andorra",
  },
  {
    code: "+244",
    name: "Angola",
  },
  {
    code: "+1 264",
    name: "Anguilla",
  },
  {
    code: "+1 268",
    name: "Antigua and Barbuda",
  },
  {
    code: "+54",
    name: "Argentina",
  },
  {
    code: "+374",
    name: "Armenia",
  },
  {
    code: "+297",
    name: "Aruba",
  },
  {
    code: "+247",
    name: "Ascension",
  },
  {
    code: "+61",
    name: "Australia",
  },
  {
    code: "+672",
    name: "Australian External Territories",
  },
  {
    code: "+43",
    name: "Austria",
  },
  {
    code: "+994",
    name: "Azerbaijan",
  },
  {
    code: "+1 242",
    name: "Bahamas",
  },
  {
    code: "+973",
    name: "Bahrain",
  },
  {
    code: "+880",
    name: "Bangladesh",
  },
  {
    code: "+1 246",
    name: "Barbados",
  },
  {
    code: "+1 268",
    name: "Barbuda",
  },
  {
    code: "+375",
    name: "Belarus",
  },
  {
    code: "+32",
    name: "Belgium",
  },
  {
    code: "+501",
    name: "Belize",
  },
  {
    code: "+229",
    name: "Benin",
  },
  {
    code: "+1 441",
    name: "Bermuda",
  },
  {
    code: "+975",
    name: "Bhutan",
  },
  {
    code: "+591",
    name: "Bolivia",
  },
  {
    code: "+387",
    name: "Bosnia and Herzegovina",
  },
  {
    code: "+267",
    name: "Botswana",
  },
  {
    code: "+55",
    name: "Brazil",
  },
  {
    code: "+246",
    name: "British Indian Ocean Territory",
  },
  {
    code: "+1 284",
    name: "British Virgin Islands",
  },
  {
    code: "+673",
    name: "Brunei",
  },
  {
    code: "+359",
    name: "Bulgaria",
  },
  {
    code: "+226",
    name: "Burkina Faso",
  },
  {
    code: "+257",
    name: "Burundi",
  },
  {
    code: "+855",
    name: "Cambodia",
  },
  {
    code: "+237",
    name: "Cameroon",
  },
  {
    code: "+1",
    name: "Canada",
  },
  {
    code: "+238",
    name: "Cape Verde",
  },
  {
    code: "+ 345",
    name: "Cayman Islands",
  },
  {
    code: "+236",
    name: "Central African Republic",
  },
  {
    code: "+235",
    name: "Chad",
  },
  {
    code: "+56",
    name: "Chile",
  },
  {
    code: "+86",
    name: "China",
  },
  {
    code: "+61",
    name: "Christmas Island",
  },
  {
    code: "+61",
    name: "Cocos-Keeling Islands",
  },
  {
    code: "+57",
    name: "Colombia",
  },
  {
    code: "+269",
    name: "Comoros",
  },
  {
    code: "+242",
    name: "Congo",
  },
  {
    code: "+243",
    name: "Congo, Dem. Rep. of (Zaire)",
  },
  {
    code: "+682",
    name: "Cook Islands",
  },
  {
    code: "+506",
    name: "Costa Rica",
  },
  {
    code: "+385",
    name: "Croatia",
  },
  {
    code: "+53",
    name: "Cuba",
  },
  {
    code: "+599",
    name: "Curacao",
  },
  {
    code: "+537",
    name: "Cyprus",
  },
  {
    code: "+420",
    name: "Czech Republic",
  },
  {
    code: "+45",
    name: "Denmark",
  },
  {
    code: "+246",
    name: "Diego Garcia",
  },
  {
    code: "+253",
    name: "Djibouti",
  },
  {
    code: "+1 767",
    name: "Dominica",
  },
  {
    code: "+1 809",
    name: "Dominican Republic",
  },
  {
    code: "+670",
    name: "East Timor",
  },
  {
    code: "+56",
    name: "Easter Island",
  },
  {
    code: "+593",
    name: "Ecuador",
  },
  {
    code: "+20",
    name: "Egypt",
  },
  {
    code: "+503",
    name: "El Salvador",
  },
  {
    code: "+240",
    name: "Equatorial Guinea",
  },
  {
    code: "+291",
    name: "Eritrea",
  },
  {
    code: "+372",
    name: "Estonia",
  },
  {
    code: "+251",
    name: "Ethiopia",
  },
  {
    code: "+500",
    name: "Falkland Islands",
  },
  {
    code: "+298",
    name: "Faroe Islands",
  },
  {
    code: "+679",
    name: "Fiji",
  },
  {
    code: "+358",
    name: "Finland",
  },
  {
    code: "+33",
    name: "France",
  },
  {
    code: "+596",
    name: "French Antilles",
  },
  {
    code: "+594",
    name: "French Guiana",
  },
  {
    code: "+689",
    name: "French Polynesia",
  },
  {
    code: "+241",
    name: "Gabon",
  },
  {
    code: "+220",
    name: "Gambia",
  },
  {
    code: "+995",
    name: "Georgia",
  },
  {
    code: "+49",
    name: "Germany",
  },
  {
    code: "+233",
    name: "Ghana",
  },
  {
    code: "+350",
    name: "Gibraltar",
  },
  {
    code: "+30",
    name: "Greece",
  },
  {
    code: "+299",
    name: "Greenland",
  },
  {
    code: "+1 473",
    name: "Grenada",
  },
  {
    code: "+590",
    name: "Guadeloupe",
  },
  {
    code: "+1 671",
    name: "Guam",
  },
  {
    code: "+502",
    name: "Guatemala",
  },
  {
    code: "+224",
    name: "Guinea",
  },
  {
    code: "+245",
    name: "Guinea-Bissau",
  },
  {
    code: "+595",
    name: "Guyana",
  },
  {
    code: "+509",
    name: "Haiti",
  },
  {
    code: "+504",
    name: "Honduras",
  },
  {
    code: "+852",
    name: "Hong Kong SAR China",
  },
  {
    code: "+36",
    name: "Hungary",
  },
  {
    code: "+354",
    name: "Iceland",
  },
  {
    code: "+91",
    name: "India",
  },
  {
    code: "+62",
    name: "Indonesia",
  },
  {
    code: "+98",
    name: "Iran",
  },
  {
    code: "+964",
    name: "Iraq",
  },
  {
    code: "+353",
    name: "Ireland",
  },
  {
    code: "+972",
    name: "Israel",
  },
  {
    code: "+39",
    name: "Italy",
  },
  {
    code: "+225",
    name: "Ivory Coast",
  },
  {
    code: "+1 876",
    name: "Jamaica",
  },
  {
    code: "+81",
    name: "Japan",
  },
  {
    code: "+962",
    name: "Jordan",
  },
  {
    code: "+7 7",
    name: "Kazakhstan",
  },
  {
    code: "+254",
    name: "Kenya",
  },
  {
    code: "+686",
    name: "Kiribati",
  },
  {
    code: "+965",
    name: "Kuwait",
  },
  {
    code: "+996",
    name: "Kyrgyzstan",
  },
  {
    code: "+856",
    name: "Laos",
  },
  {
    code: "+371",
    name: "Latvia",
  },
  {
    code: "+961",
    name: "Lebanon",
  },
  {
    code: "+266",
    name: "Lesotho",
  },
  {
    code: "+231",
    name: "Liberia",
  },
  {
    code: "+218",
    name: "Libya",
  },
  {
    code: "+423",
    name: "Liechtenstein",
  },
  {
    code: "+370",
    name: "Lithuania",
  },
  {
    code: "+352",
    name: "Luxembourg",
  },
  {
    code: "+853",
    name: "Macau SAR China",
  },
  {
    code: "+389",
    name: "Macedonia",
  },
  {
    code: "+261",
    name: "Madagascar",
  },
  {
    code: "+265",
    name: "Malawi",
  },
  {
    code: "+60",
    name: "Malaysia",
  },
  {
    code: "+960",
    name: "Maldives",
  },
  {
    code: "+223",
    name: "Mali",
  },
  {
    code: "+356",
    name: "Malta",
  },
  {
    code: "+692",
    name: "Marshall Islands",
  },
  {
    code: "+596",
    name: "Martinique",
  },
  {
    code: "+222",
    name: "Mauritania",
  },
  {
    code: "+230",
    name: "Mauritius",
  },
  {
    code: "+262",
    name: "Mayotte",
  },
  {
    code: "+52",
    name: "Mexico",
  },
  {
    code: "+691",
    name: "Micronesia",
  },
  {
    code: "+1 808",
    name: "Midway Island",
  },
  {
    code: "+373",
    name: "Moldova",
  },
  {
    code: "+377",
    name: "Monaco",
  },
  {
    code: "+976",
    name: "Mongolia",
  },
  {
    code: "+382",
    name: "Montenegro",
  },
  {
    code: "+1664",
    name: "Montserrat",
  },
  {
    code: "+212",
    name: "Morocco",
  },
  {
    code: "+95",
    name: "Myanmar",
  },
  {
    code: "+264",
    name: "Namibia",
  },
  {
    code: "+674",
    name: "Nauru",
  },
  {
    code: "+977",
    name: "Nepal",
  },
  {
    code: "+31",
    name: "Netherlands",
  },
  {
    code: "+599",
    name: "Netherlands Antilles",
  },
  {
    code: "+1 869",
    name: "Nevis",
  },
  {
    code: "+687",
    name: "New Caledonia",
  },
  {
    code: "+64",
    name: "New Zealand",
  },
  {
    code: "+505",
    name: "Nicaragua",
  },
  {
    code: "+227",
    name: "Niger",
  },
  {
    code: "+234",
    name: "Nigeria",
  },
  {
    code: "+683",
    name: "Niue",
  },
  {
    code: "+672",
    name: "Norfolk Island",
  },
  {
    code: "+850",
    name: "North Korea",
  },
  {
    code: "+1 670",
    name: "Northern Mariana Islands",
  },
  {
    code: "+47",
    name: "Norway",
  },
  {
    code: "+968",
    name: "Oman",
  },
  {
    code: "+92",
    name: "Pakistan",
  },
  {
    code: "+680",
    name: "Palau",
  },
  {
    code: "+970",
    name: "Palestinian Territory",
  },
  {
    code: "+507",
    name: "Panama",
  },
  {
    code: "+675",
    name: "Papua New Guinea",
  },
  {
    code: "+595",
    name: "Paraguay",
  },
  {
    code: "+51",
    name: "Peru",
  },
  {
    code: "+63",
    name: "Philippines",
  },
  {
    code: "+48",
    name: "Poland",
  },
  {
    code: "+351",
    name: "Portugal",
  },
  {
    code: "+1 787",
    name: "Puerto Rico",
  },
  {
    code: "+974",
    name: "Qatar",
  },
  {
    code: "+262",
    name: "Reunion",
  },
  {
    code: "+40",
    name: "Romania",
  },
  {
    code: "+7",
    name: "Russia",
  },
  {
    code: "+250",
    name: "Rwanda",
  },
  {
    code: "+685",
    name: "Samoa",
  },
  {
    code: "+378",
    name: "San Marino",
  },
  {
    code: "+966",
    name: "Saudi Arabia",
  },
  {
    code: "+221",
    name: "Senegal",
  },
  {
    code: "+381",
    name: "Serbia",
  },
  {
    code: "+248",
    name: "Seychelles",
  },
  {
    code: "+232",
    name: "Sierra Leone",
  },
  {
    code: "+65",
    name: "Singapore",
  },
  {
    code: "+421",
    name: "Slovakia",
  },
  {
    code: "+386",
    name: "Slovenia",
  },
  {
    code: "+677",
    name: "Solomon Islands",
  },
  {
    code: "+27",
    name: "South Africa",
  },
  {
    code: "+500",
    name: "South Georgia and the South Sandwich Islands",
  },
  {
    code: "+82",
    name: "South Korea",
  },
  {
    code: "+34",
    name: "Spain",
  },
  {
    code: "+94",
    name: "Sri Lanka",
  },
  {
    code: "+249",
    name: "Sudan",
  },
  {
    code: "+597",
    name: "Suriname",
  },
  {
    code: "+268",
    name: "Swaziland",
  },
  {
    code: "+46",
    name: "Sweden",
  },
  {
    code: "+41",
    name: "Switzerland",
  },
  {
    code: "+963",
    name: "Syria",
  },
  {
    code: "+886",
    name: "Taiwan",
  },
  {
    code: "+992",
    name: "Tajikistan",
  },
  {
    code: "+255",
    name: "Tanzania",
  },
  {
    code: "+66",
    name: "Thailand",
  },
  {
    code: "+670",
    name: "Timor Leste",
  },
  {
    code: "+228",
    name: "Togo",
  },
  {
    code: "+690",
    name: "Tokelau",
  },
  {
    code: "+676",
    name: "Tonga",
  },
  {
    code: "+1 868",
    name: "Trinidad and Tobago",
  },
  {
    code: "+216",
    name: "Tunisia",
  },
  {
    code: "+90",
    name: "Turkey",
  },
  {
    code: "+993",
    name: "Turkmenistan",
  },
  {
    code: "+1 649",
    name: "Turks and Caicos Islands",
  },
  {
    code: "+688",
    name: "Tuvalu",
  },
  {
    code: "+1 340",
    name: "U.S. Virgin Islands",
  },
  {
    code: "+256",
    name: "Uganda",
  },
  {
    code: "+380",
    name: "Ukraine",
  },
  {
    code: "+971",
    name: "United Arab Emirates",
  },
  {
    code: "+44",
    name: "United Kingdom",
  },
  {
    code: "+1",
    name: "United States",
  },
  {
    code: "+598",
    name: "Uruguay",
  },
  {
    code: "+998",
    name: "Uzbekistan",
  },
  {
    code: "+678",
    name: "Vanuatu",
  },
  {
    code: "+58",
    name: "Venezuela",
  },
  {
    code: "+84",
    name: "Vietnam",
  },
  {
    code: "+1 808",
    name: "Wake Island",
  },
  {
    code: "+681",
    name: "Wallis and Futuna",
  },
  {
    code: "+967",
    name: "Yemen",
  },
  {
    code: "+260",
    name: "Zambia",
  },
  {
    code: "+255",
    name: "Zanzibar",
  },
  {
    code: "+263",
    name: "Zimbabwe",
  },
];

export const jobCategories = [
  { key: "Operations", label: "Operations", value: "Operations" },
  { key: "Supervisor", label: "Supervisor", value: "Supervisor" },
  { key: "Managerial", label: "Managerial", value: "Managerial" },
  {
    key: "Executive Leadership",
    label: "Executive Leadership",
    value: "Executive Leadership",
  },
  { key: "Director", label: "Director", value: "Director" },
];
export const roles = [
  { key: "Employee", label: "Employee", value: "Employee" },
  { key: "Manager", label: "Manager", value: "Manager" },
  { key: "HR Admin", label: "HR Admin", value: "HR Admin" },
  { key: "Super Admin", label: "Super Admin", value: "Super Admin" },
];
export const statuses = [
  { key: "On Track", label: "On Track", value: "On Track" },
  { key: "At Risk", label: "At Risk", value: "At Risk" },
  { key: "Off Track", label: "Off Track", value: "Off Track" },
];
export const statusesActive = [
  { key: "Active", label: "Active", value: "Active" },
  { key: "Inactive", label: "Inactive", value: "Inactive" },
];
export const loginMethods = [
  { key: "SSO", label: "SSO", value: "SSO" },
  { key: "Manual", label: "Manual", value: "Manual" },
];
export const genders = [
  { key: "Male", label: "Male", value: "Male" },
  { key: "Female", label: "Female", value: "Female" },
  { key: "Others", label: "Others", value: "Others" },
];
export const countriesNames = [
  { key: "India", label: "India", value: "India" },
  { key: "United States", label: "United States", value: "United States" },
  {
    key: "United Arab Emirates",
    label: "United Arab Emirates",
    value: "United Arab Emirates",
  },
];
export const industries = [
  { key: "All", label: "All", value: "All" },
  { key: "Aviation", label: "Aviation", value: "Aviation" },
  { key: "Hospitality", label: "Hospitality", value: "Hospitality" },
  { key: "BFSI", label: "BFSI", value: "BFSI" },
  { key: "Education", label: "Education", value: "Education" },
  { key: "ITES", label: "ITES", value: "ITES" },
  {
    key: "Non-Profit Organization",
    label: "Non-Profit Organization",
    value: "Non-Profit Organization",
  },
  { key: "Real Estate", label: "Real Estate", value: "Real Estate" },
  { key: "Energy", label: "Energy", value: "Energy" },
  { key: "Free-Trail", label: "Free-Trail", value: "Free-Trail" },
  {
    key: "Semiconductor/Chip Design",
    label: "Semiconductor/Chip Design",
    value: "Semiconductor/Chip Design",
  },
  { key: "Others", label: "Others", value: "Others" },
];
export const OKRperiod = [
  { key: "Q1", label: "Q1", value: "Q1" },
  { key: "Q2", label: "Q2", value: "Q2" },
  { key: "Q3", label: "Q3", value: "q3" },
  { key: "Q4", label: "Q4", value: "Q4" },
];
export const Region = [
  { key: "Please Select", label: "Please Select", value: "" },
  { key: "Americans", label: "Americans", value: "Americans" },
  { key: "Asia Pacific", label: "Asia Pacific", value: "Asia Pasific" },
  { key: "Europe", label: "Europe", value: "Europe" },
  {
    key: "Middel East & Africa",
    label: "Middel East & Africa",
    value: "Middel East & Africa",
  },
];
export const Organization = [
  { key: "Please Select", label: "Please Select", value: "" },
  { key: "<50 employees", label: "<50 employees", value: "<50 employees" },
  {
    key: "50-200 employees",
    label: "50-200 employees",
    value: "50-200 employees",
  },
  {
    key: "200-1000 employees",
    label: "200-1000 employees",
    value: "200-1000 employees",
  },
  {
    key: "1000+ employees",
    label: "1000+ employees",
    value: "1000+ employees",
  },
];
export const OKRUser = [
  { key: "User1", label: "User1", value: "User1" },
  { key: "User2", label: "User2", value: "User2" },
];
export const OKRYear = [
  { key: "2021", label: "2021", value: "2021" },
  { key: "2022", label: "2022", value: "2022" },
  { key: "2023", label: "2023", value: "2023" },
  { key: "2024", label: "2024", value: "2024" },
  { key: "2025", label: "2025", value: "2025" },
  { key: "2026", label: "2026", value: "2026" },
  { key: "2027", label: "2027", value: "2027" },
  { key: "2028", label: "2028", value: "2028" },
  { key: "2029", label: "2029", value: "2029" },
  { key: "2030", label: "2030", value: "2030" },
  { key: "2031", label: "2031", value: "2031" },
];

export const Frequencies = [
  { key: "--Select--", label: "--Select--", value: "" },
  { key: "Weekly", label: "Weekly", value: "Weekly" },
  { key: "Semi Monthly", label: "Semi Monthly", value: "Semi Monthly" },
  { key: "Monthly", label: "Monthly", value: "Monthly" },
  { key: "Quarterly", label: "Quarterly", value: "Quarterly" },
  { key: "Semi Anually", label: "Semi Anually", value: "Semi Anually" },
  { key: "Anually", label: "Anually", value: "Anually" },
];

export const UOMs = [
  { key: "--Select--", label: "--Select--", value: "" },
  { key: "Number", label: "Number", value: "Number" },
  { key: "Percentage(%)", label: "Percentage(%)", value: "Percentage(%)" },
  { key: "INR", label: "INR", value: "INR" },
  { key: "USD", label: "USD", value: "USD" },
  { key: "AED", label: "AED", value: "AED" },
];

export const Polarities = [
  { key: "--Select--", label: "--Select--", value: "" },
  { key: "Positive", label: "Positive", value: "Positive" },
  { key: "Negative", label: "Negative", value: "Negative" },
];

export const MSCs = [
  { key: "--Select--", label: "--Select--", value: "" },
  { key: "Must Have", label: "Must Have", value: "Must Have" },
  { key: "Should Have", label: "Should Have", value: "Should Have" },
  { key: "Could Have", label: "Could Have", value: "Could Have" },
];

export const Dimensions = [
  { key: "--Select--", label: "--Select--", value: "" },
  { key: "People", label: "People", value: "People" },
  { key: "Financial", label: "Financial", value: "Financial" },
  { key: "Operations", label: "Operations", value: "Operations" },
  { key: "Strategy", label: "Strategy", value: "Strategy" },
  {
    key: "Process/Governance/System",
    label: "Process/Governance/System",
    value: "Process/Governance/System",
  },
];

export const dummyData = [
  { key: "Product", label: "Product", value: "Product" },
  { key: "Product2", label: "Product2", value: "Product2" },
];

export const operations = [
  { key: "<", label: "<", value: "lt" },
  { key: ">", label: ">", value: "gt" },
  { key: "=", label: "=", value: "eq" },
];

export const list1 = [
  "Our OKRs align easily, and bridge your strategy-execution gap.",
  "Take advantage of this powerful goal-setting framework with benefits like:",
];

export const list2 = [
  "Encourage company wide collaboration and bottom up communication",
  "Agile alignment",
  "Faster adaptation and execution",
  "Guided weekly check-ins",
];

export const list11 = [
  "Boost employee performance with customizable features to track and monitor",
  "Build a high performance workplace culture with benefits like:",
];

export const list21 = [
  "Efficient Performance Appraisal Management",
  "High-performance work culture",
  "Talent identification and nurturing",
];

export const list31 = [
  "Engagement with a peer-based award and recognition program",
  "Strengthen employee engagement and measure satisfaction with these key features:",
];

export const list32 = [
  "Employee awards points",
  "Pulse surveys",
  "Company news feed",
];

export const list41 = [
  "Make your team’s work more efficient with our intuitive Task Management Software",
  "Connect day-to-day work to higher order company goals and harvest benefits like:",
];

export const list42 = [
  "Workflows aligned with goals",
  "Organized teamwork",
  "Streamlined Project Execution",
];

export const list51 = [
  "We provide AI based employee SWOT analysis and Team SWOT analysis along with eNPS.",
];

export const list52 = [
  "Advantages of this analysis includes",
  "Better Retention Strategy",
  "Formulate strategy to improve team productivity",
];

export const companyList = [
  { key: "About Us", path: "" },
  { key: "Product", path: "" },
  { key: "In the Press", path: "" },
  { key: "Partnership", path: "" },
  { key: "Terms of Service", path: "" },
  { key: "SLA", path: "" },
  { key: "Privacy Policy", path: "" },
  { key: "Data Security", path: "" },
];

export const blogList = [
  { key: "OKR University", path: "" },
  { key: "KPIs Library", path: "" },
  { key: "Performance Management", path: "" },
  { key: "Power of the Letter P", path: "" },
  { key: "Behavioral Economics", path: "" },
  { key: "OKR Examples", path: "" },
  { key: "Integrations", path: "" },
  { key: "Webinar", path: "" },
];

export const supportList = [
  { key: "SUPPORT", path: "" },
  { key: "Contact UsAnswersOKR", path: "" },
  { key: "CanvasHelp", path: "" },
  { key: "CenterIterate Faster", path: "" },
  { key: "Coaching", path: "" },
];

export const priority = [
  { key: "High Level", value: "High Level" },
  { key: "Medium Level", value: "Medium Level" },
  { key: "Low Level", value: "Low Level" },
];

export const taskStatus = [
  { key: "Not Started", value: "notstarted" },
  { key: "In Progress", value: "inprogress" },
  { key: "Completed", value: "completed" },
];
export const Role = [
  { key: "--Select--", value: "" },
  { key: "Employee", value: "Employee" },
  { key: "Manager", value: "Manager" },
  { key: "HR Admin", value: "HR Admin" },
  { key: "Super Admin", value: "Super Admin" },
];

export const Categories = [
  { key: "--Select--", value: "" },
  { key: "Designation", value: "Designation" },
  { key: "Department", value: "Department" },
  { key: "Grade", value: "Grade" },
  { key: "Hire Date", value: "Hire Date" },
];

export const ExcludeCategories = [
  { key: "--Select--", value: "" },
  { key: "Designation", value: "Designation" },
  { key: "Department", value: "Department" },
  { key: "Grade", value: "Grade" },
  { key: "Hire Date", value: "Hire Date" },
  { key: "Email", value: "Email" },
];

export const OKRFunctionOptions = [
  { key: "--Select--", value: "" },
  { key: "Human Resources", value: "Human Resources", isSelected: false },
  { key: "Finance", value: "Finance", isSelected: false },
  { key: "Procurement", value: "Procurement", isSelected: false },
  { key: "Admin Operations", value: "Admin Operations", isSelected: false },
];
export const OKRFunctionOptionsSelect = [
  { key: "Human Resources", value: "Human Resources", isSelected: false },
  { key: "Finance", value: "Finance", isSelected: false },
  { key: "Procurement", value: "Procurement", isSelected: false },
  { key: "Admin Operations", value: "Admin Operations", isSelected: false },
];

export const OKRCategoryOptions = [
  { key: "--Select--", value: "" },
  { key: "Operational", value: "Operational" },
  { key: "Supervisor", value: "Supervisor" },
  { key: "Mid Management", value: "Mid Management" },
  { key: "Executive Management", value: "Executive Management" },
];
export const RewardCategories = [
  { key: "--Select--", value: "" },
  { key: "Monetory", value: "Monetory" },
  { key: "Non Monetory", value: "Non Monetory" },
];

export const RewardTypes1 = [
  { key: "--Select--", value: "" },
  { key: "Amazon", value: "Amazon" },
  { key: "Big Basket", value: "Big Basket" },
  { key: "Book My Show", value: "Book My Show" },
  { key: "Flipkart", value: "Flipkart" },
  { key: "Netflix Subscription", value: "Netflix Subscription" },
];

export const RewardTypes2 = [
  { key: "--Select--", value: "" },
  { key: "Leave Credits", value: "Leave Credits" },
  { key: "Remote Working", value: "Remote Working" },
  { key: "Flexible Working Hours", value: "Flexible Working Hours" },
  { key: "Performance", value: "Performance" },
];
export const RewardPoints = [
  { key: "--Select Range--", value: "" },
  { key: "Gold", value: "Gold" },
  { key: "Silver", value: "Silver" },
  { key: "Bronze", value: "Bronze" },
];

export const actionsData = [
  {
    category: "Objectives",
    page: "Create Objective",
    active: false,
    toAddress: "",
    ccAddress: "",
    subject: "",
    message: "",
    attachment: "",
  },
  {
    category: "Objectives",
    page: "Update Objective",
    active: false,
    toAddress: "",
    ccAddress: "",
    subject: "",
    message: "",
    attachment: "",
  },
  {
    category: "Objectives",
    page: "Delete Objective",
    active: false,
    toAddress: "",
    ccAddress: "",
    subject: "",
    message: "",
    attachment: "",
  },
  {
    category: "Objectives",
    page: "Unlock Objective",
    active: false,
    toAddress: "",
    ccAddress: "",
    subject: "",
    message: "",
    attachment: "",
  },
  {
    category: "Objectives",
    page: "Approval",
    active: false,
    toAddress: "",
    ccAddress: "",
    subject: "",
    message: "",
    attachment: "",
  },
  {
    category: "Objectives",
    page: "Reject",
    active: false,
    toAddress: "",
    ccAddress: "",
    subject: "",
    message: "",
    attachment: "",
  },
  {
    category: "Objectives",
    page: "Submission",
    active: false,
    toAddress: "",
    ccAddress: "",
    subject: "",
    message: "",
    attachment: "",
  },
  {
    category: "Key Results",
    page: "Create KR",
    active: false,
    toAddress: "",
    ccAddress: "",
    subject: "",
    message: "",
    attachment: "",
  },
  {
    category: "Key Results",
    page: "Update KR",
    active: false,
    toAddress: "",
    ccAddress: "",
    subject: "",
    message: "",
    attachment: "",
  },
  {
    category: "Key Results",
    page: "Delete KR",
    active: false,
    toAddress: "",
    ccAddress: "",
    subject: "",
    message: "",
    attachment: "",
  },
  {
    category: "Tasks",
    page: "Create Task",
    active: false,
    toAddress: "",
    ccAddress: "",
    subject: "",
    message: "",
    attachment: "",
  },
  {
    category: "Tasks",
    page: "Update Task",
    active: false,
    toAddress: "",
    ccAddress: "",
    subject: "",
    message: "",
    attachment: "",
  },
  {
    category: "Tasks",
    page: "Delete Task",
    active: false,
    toAddress: "",
    ccAddress: "",
    subject: "",
    message: "",
    attachment: "",
  },
];

export const OKRperiodMonths = [
  { key: "Monthly", label: "Monthly", value: "Monthly" },
  { key: "Weekly", label: "Weekly", value: "Weekly" },
  { key: "Daily", label: "Daily", value: "Daily" },
  { key: "Yearly", label: "Yearly", value: "Yearly" },
];
export const data1 = [
  { name: "Team Tasks", students: 59.3 },
  { name: "pie2.1", students: 40.7 },
];
export const data2 = [
  { name: "Team Reward points", students: 59.3 },
  { name: "pie2.1", students: 40.7 },
];
export const data3 = [
  { name: "Team kudos Received", students: 51.3 },
  { name: "pie2.1", students: 49.7 },
];
export const data4 = [
  { name: "Team Acheivement", students: 71.3 },
  { name: "pie2.1", students: 28.7 },
];
export const questionData = [
  { key: "How to create objective ?", value: "objectives", action: "story" },
  { key: "How to update objective ?", value: "objectives", action: "story1" },
  {
    key: "How to submit objectives for approval?",
    value: "objectives",
    action: "story2",
  },
  { key: "How to add kr to objective?", value: "objectives", action: "story3" },
  { key: "How to create task?", value: "tasks", action: "story" },
  { key: "How to update task?", value: "tasks", action: "story1" },
  {
    key: "How to view reward points?",
    value: "rewards/rewardsRedemption",
    action: "story",
  },
  {
    key: "How to redeem reward points?",
    value: "rewards/rewardsRedemption",
    action: "story1",
  },
];
export const HRAdminData = [
  {
    key: "How to view my company activities?",
    value: "dashboard/me",
    action: "story",
  },
];
export const ManagerData = [
  { key: "How to cascade Objectives?", value: "objectives", action: "story4" },
  {
    key: "How to view my team activities?",
    value: "dashboard/me",
    action: "view",
  },
];

export const data5 = [{ title: "Jason W", amount: 12524 }];

export const defaultProfilePic =
  "https://www.clipartmax.com/png/middle/103-1038880_user-rubber-stamp-female-user-icon.png";

export const recurrenceOptions = [
  { label: "Weekly", value: "Week" },
  { label: "Monthly", value: "Month" },
  { label: "Quarterly", value: "Quarter" },
];

export const recurrenceNumbers = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
];

export const daysOptions = [
  { label: "S", value: 0 },
  { label: "M", value: 1 },
  { label: "T", value: 2 },
  { label: "W", value: 3 },
  { label: "T", value: 4 },
  { label: "F", value: 5 },
  { label: "S", value: 6 },
];
export const recurrenceEndOptions = [
  { label: "on this day", value: "on this day" },
];

export const recurrenceDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednessday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const roleTypes = {
  Employee: "Employee",
  Manager: "Manager",
  HRAdmin: "HRAdmin",
  SuperAdmin: "SuperAdmin",
};

export const cascadeTabs = {
  Individual: "Individual",
  Team: "Team",
  Company: "Company",
};
