import useWindowSize from "components/UseWindowSize";
import React from "react";
import { countries } from "utilities";
import "./styles.scss";

export default function TextInput({
  label = "",
  text,
  placeholder = "",
  style,
  name = "",
  dateType,
  value = "",
  onChangeText,
  index,
  isCountry,
  countryCode = "+91",
  onChangeCountry,
  readonly,
  labelStyle,
  inputStyle,
  ...rest
}) {
  const isMobile = useWindowSize();
  return (
    <div className={`${isMobile ? 'd-flex justify-content-between' : 'd-flex justify-content-between'}`}>
      {label && <label className={isMobile ? "label fs13 col-md-4 col-xs-12 col-sm-12 p-0" : `label fs13 col-md-4 col-xs-12 col-sm-12 ${labelStyle}`}>{label}</label>}
      <div className={`d-flex col-md-8  col-xs-12 col-sm-12 ${isMobile ? 'm-0 p-0' : 'ml-2'} ${inputStyle}`}>
        {isCountry && (
          <select
            className={isMobile ? "form-control col-md-4 rightRadius p-0" : "form-control col-md-4 rightRadius "}
            defaultValue={countryCode}
            value={countryCode}
            onChange={onChangeCountry}
          >
            {countries.map((country, index) => (
              <option value={country.code} key={index}>
                {country.code}
              </option>
            ))}
          </select>
        )}
        {readonly ? (
          <input
            type={text ? text : dateType}
            className={`form-control  rounded ${isCountry ? "leftradius" : ""}`}
            index={index}
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={onChangeText}
            readOnly
            {...rest}
          />
        ) : (
          <input
            type={text ? text : dateType}
            className={`form-control  rounded ${isCountry ? "leftradius" : ""}`}
            placeholder={placeholder}
            name={name}
            value={value}
            index={index}
            onChange={onChangeText}
            {...rest}
          />
        )}
      </div>
    </div>
  );
}
