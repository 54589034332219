/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "./styles.scss";
import wrong from "assets/svg/wrong.svg";
import { Col, Row } from "react-bootstrap";
import TextInput from "components/Company/TextInput";
import SelectInput from "components/Company/SelectInput";
import { getKeyResults } from "action/keyResultAct";
import { useDispatch } from "react-redux";
import { getEmployees } from "action/EmployeeAct";
import { removeDuplicates, taskStatus, Validator } from "utilities";
import { useTranslation } from "react-i18next";
const TasksView = (props) => {
  const [data, setData] = useState(props.data);
  const dispatch = useDispatch();
  const [change, setChange] = useState(false);
  const [linkChange, setLinkChange] = useState(false);
  const [, setLoading] = useState(false);
  const [getKeyResult, setGetKeyResult] = useState();
  const [, setError] = useState(false);
  const [keyResultw, setKeyResultw] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [showAttachment, setShowAttachment] = useState(
    props.data.attachments ? false : true
  );

  const validator = Validator();
  const priority = [
    { key: "High Level", value: "High Level" },
    { key: "Medium Level", value: "Medium Level" },
    { key: "Low Level", value: "Low Level" },
  ];
  const fetchEntities = () => {
    try {
      setLoading(true);
      let response = dispatch(getKeyResults());
      response.then(({ data, message }) => {
        if (data !== undefined && data.length > 0) {
          let updatedData = data.map((item) => {
            return { key: item.keyResultName, value: item._id };
          });
          setKeyResultw(updatedData);
          setGetKeyResult(data);
          setLoading(false);
          setError("");
        } else if (data.length === 0) {
          setLoading(false);
          setError("No Data Found!");
        } else {
          setLoading(false);
          setError(message);
        }
      });
    } catch (error) {
      setLoading(false);
      setError(error.toString());
    }
  };
  const fetchEmployees = () => {
    try {
      setLoading(true);
      let response = dispatch(getEmployees());
      response.then(({ data, message }) => {
        if (data !== undefined && data.length > 0) {
          let updatedData = data.map((item) => {
            return {
              key:
                item.personalInformation.firstName +
                " " +
                item.personalInformation.lastName,
              value: item._id,
            };
          });
          let nonduplicate = removeDuplicates(updatedData, "key");
          setEmployees(nonduplicate);
          setLoading(false);
          setError("");
        } else if (data.length === 0) {
          setLoading(false);
          setError("No Data Found!");
        } else {
          setLoading(false);
          setError(message);
        }
      });
    } catch (error) {
      setLoading(false);
      setError(error.toString());
    }
  };

  const handleChange = ({ target: { name, value } }) => {
    if (name === "assignTo") {
      setChange(true);
    }
    if (name === "linkToKR") {
      setLinkChange(true);
    }
    let updatedData = { ...data };
    updatedData[name] = value;
    setData(updatedData);
  };
  useEffect(() => {
    fetchEntities();
    fetchEmployees();
    //eslint-disable-next-line
  }, []);

  const { t } = useTranslation();
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        style={{
          background: "#F5F5F6",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.17)",
        }}
      >
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ paddingTop: "10px", paddingLeft: "20px" }}
        >
          View Task
        </Modal.Title>
        <img
          src={wrong}
          alt="wrong"
          onClick={props.onHide}
          style={{ cursor: "pointer" }}
        />
      </Modal.Header>
      <Modal.Body>
        <div className="bg-light-white rounded-12 m-4">
          <div className="form-group d-flex justify-content-between">
            <label htmlFor="taskTitle">{t("Tasks.Task Title")}</label>
            <input
              type="text"
              placeholder=""
              id="title"
              className="form-control col-10 bg-light searchBox text-dark fs14"
              name="title"
              value={data.title}
              onChange={handleChange}
              readOnly
            />
          </div>
          <div className="form-group d-flex justify-content-between">
            <label htmlFor="description">Task Description</label>
            <textarea
              id="description"
              className="form-control col-10 p-3"
              rows="5"
              name="description"
              value={data.description}
              onChange={handleChange}
              readOnly
            />
          </div>
          <div>
            <Row>
              <Col>
                <TextInput
                  label={t("Tasks.Start Date")}
                  dateType="date"
                  name="startDate"
                  value={window.moment(data.startDate).format("YYYY-MM-DD")}
                  onChangeText={handleChange}
                  readonly="readOnly"
                />
              </Col>
              <Col>
                <TextInput
                  label={t("Tasks.Due Date")}
                  dateType="date"
                  name="dueDate"
                  value={window.moment(data.dueDate).format("YYYY-MM-DD")}
                  onChangeText={handleChange}
                  readonly="readOnly"
                />
              </Col>
              <Col className="mt-3">
                <TextInput
                  label={t("Tasks.Actual Completion Date")}
                  dateType="date"
                  name="actualCompletionDate"
                  value={window
                    .moment(data.actualCompletionDate)
                    .format("YYYY-MM-DD")}
                  onChangeText={handleChange}
                  readonly="readOnly"
                />
              </Col>
            </Row>
          </div>
          <div>
            <Row className="mt-2 mb-2">
              <Col>
                <SelectInput
                  label={t("Tasks.Link To KR")}
                  placeholder=""
                  name="linkToKR"
                  options={keyResultw}
                  value={linkChange ? data.linkToKR : data.krReferenceId}
                  onChangeText={handleChange}
                  readonly="readOnly"
                />
              </Col>
              <Col>
                <SelectInput
                  label={t("Tasks.Assign To")}
                  placeholder=""
                  name="assignTo"
                  options={employees}
                  value={change ? data.assignTo : data.assignTo[0]}
                  onChangeText={handleChange}
                  readonly="readOnly"
                />
              </Col>
            </Row>
            <Row className="mt-2 mb-2">
              <Col>
                <SelectInput
                  label={t("Tasks.Priority")}
                  placeholder=""
                  name="priority"
                  options={priority}
                  value={data.priority}
                  onChangeText={handleChange}
                  readonly="readOnly"
                />
              </Col>
              <Col>
                <SelectInput
                  Recurrence
                  placeholder=""
                  name="status"
                  options={taskStatus}
                  value={data.status}
                  onChangeText={handleChange}
                  readonly="readOnly"
                />
              </Col>
            </Row>
            <Row className="mt-2 mb-2">
              <Col className="p-0">
                <TextInput
                  label={t("OKR Details.Estimation Efforts")}
                  dateType="number"
                  name="estimationEffort"
                  value={data.estimationEffort}
                  onChangeText={handleChange}
                  readonly="readOnly"
                />
              </Col>
              <Col className="p-0">
                <TextInput
                  label={t("OKR Details.Actual Efforts")}
                  dateType="number"
                  name="actualEffort"
                  value={data.actualEffort}
                  onChangeText={handleChange}
                  readonly="readOnly"
                />
              </Col>
            </Row>
          </div>
          <div className="form-group d-flex mt-3 justify-content-between">
            <label htmlFor="comment">Comments</label>
            <textarea
              id="comments"
              className="form-control col-10 p-3"
              rows="5"
              name="comments"
              value={data.comments}
              onChange={handleChange}
              readOnly
            ></textarea>
          </div>
          {data.attachments && data.attachments.length > 0 && (
            <div className="d-flex justify-content-between align-items-center">
              <a
                href={
                  data.attachments && data.attachments.length > 0
                    ? data.attachments
                    : null
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                View Attachment
              </a>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TasksView;
