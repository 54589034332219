import React from "react";
import Navbar from "components/Navbar";
import Sidebar from "components/Sidebar";
import { Redirect } from "react-router-dom";
import useWindowSize from "components/UseWindowSize";
export function AdminLayout(props) {
  let user = localStorage.getItem("user") !== null ? JSON.parse(localStorage.getItem("user")) : null;
  const isMobile = useWindowSize();
  if (user !== null) {
    return (
      <div className="row p-0 m-0">
        <React.Fragment>
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 m-0 bg-light">
            {/* Top Navbar */}
            <Navbar />
            {/* Main content */}
            <div className="d-flex justify-content-between">
              <div className={isMobile ? "d-none d-md-block d-lg-block" : ""}>
                {/* Left Sidebar */}
                <Sidebar />
              </div>
              <div className="p-0 m-0 w-100">{props.children}</div>
            </div>
          </div>
        </React.Fragment>
      </div>
    );
  } else {
    return (
      <Redirect to="/auth/login" />
    )
  }
}
